import Countries from "../../assets/countries-region.json";
import { useForm, Controller } from "react-hook-form";
import useDocumentTitle from "../../useDocumentTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Toast from "components/Toast";
import moment from "moment-timezone";
import Select from "react-select";
import * as yup from "yup";

import Deposit from "components/Deposit";
import Withdrawal from "components/Withdrawal";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState([{}]);
  const { t } = useTranslation();
  useDocumentTitle(`${t("user-profile.title")} | ${t("site-name")}`);
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const history = useHistory();
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const baseDomain = window.location.hostname.replace(/^account\./, "");

  const profileSchema = yup.object().shape({
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    email: yup.string().email().nullable(),
    address: yup.string().nullable(),
    phone_number: yup.string().nullable(),
    national_id: yup.string().typeError("Must be a number").nullable(),
    country: yup.string().required(t("user-profile.errors.required.country")),
    region: yup.string().required(t("user-profile.errors.required.region")),
    city: yup.string().required(t("user-profile.errors.required.city")),
    birth_date: yup.string().nullable(),
    timezone: yup.string().nullable(),
    gender: yup.string().nullable(),
    postal_code: yup.string().nullable(),
  });

  const passwordSchema = yup.object().shape({
    password: yup.string().required(t("user-profile.errors.required.password")),
    confirm_password: yup
      .string()
      .required(t("user-profile.errors.required.confirm-password")),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
  });

  const {
    register: passwordRegister,
    handleSubmit: passwordSubmit,
    reset: passwordReset,
    formState: { errors: passwordErrors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const fetchProfileData = React.useCallback(async () => {
    try {
      await fetch("api/v1/users/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setPlayer(data);
          reset({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            address: data.address,
            phone_number: data.phone_number,
            national_id: data.national_id,
            country: data.country,
            region: data.region,
            city: data.city,
            birth_date: data.birth_date
              ? new Date(data.birth_date).toISOString().split("T")[0]
              : null,
            timezone: data.timezone,
            gender: data.gender,
            postal_code: data.postal_code,
          });
        })
        .catch((error) => {
          notify("error", error.message);
          if (error.message === "Unauthorized")
            window.location.href = `https://${baseDomain}${
              selectedLanguage === "en"
                ? "/logout"
                : `/${selectedLanguage}/logout`
            }`;
        });
    } catch (error) {
      notify("error", error.message);
    }
  }, [baseDomain, notify, reset, selectedLanguage, token]);

  useEffect(() => {
    fetchProfileData();
  }, [token, history, notify, reset, fetchProfileData]);

  const handleUserProfileSubmit = (data) => {
    setLoading(true);
    fetch("api/v1/users/profile", {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => notify("success", data.message))
      .catch((error) => notify("error", error.message))
      .finally(() => setLoading(false));
  };

  const handlePasswordChange = async (data) => {
    setLoading(true);
    await fetch("api/v1/users/password", {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        passwordReset();
        notify("success", data.message);
      })
      .catch((error) => notify("error", error.message))
      .finally(() => setLoading(false));
  };

  const handleUserProfileDelete = () => {
    try {
      fetch("api/v1/users/profile", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setPlayer("");
          logout();
        })
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  };

  async function logout() {
    fetch("/cookies/clear-logout", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.href = `https://${baseDomain}${
          selectedLanguage === "en" ? "/logout" : `/${selectedLanguage}/logout`
        }`;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const timezones = moment.tz.names().map((timezone) => {
    return { value: timezone, label: timezone };
  });

  const countries = Countries.map((country) => {
    return {
      value: country.countryName,
      label: country.countryName,
      regions: country.regions.map((region) => {
        return { value: region.name, label: region.name };
      }),
    };
  });

  const genders = [
    { value: "male", label: "male" },
    { value: "female", label: "female" },
    { value: "transgender", label: "transgender" },
  ];

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card bg-secondary shadow">
            <div className="card-body bg-white border-0">
              <div className="row align-items-center">
                <div className="col-5 d-flex flex-row">
                  <i className="fas fa-wallet fa-2x text-primary"></i>
                  <div className="d-flex flex-column pl-2">
                    <h3 className="mb-0">{t("user-profile.fields.balance")}</h3>
                    <h2 className="mb-0">
                      {player.wallet?.balance.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                      })}
                    </h2>
                  </div>
                </div>

                <div className="col-1 justify-content-center">
                  <span className="border border-primary"></span>
                </div>

                <div className="col-5 d-flex flex-row justify-content-end">
                  <i className="fas fa-gift fa-2x text-primary"></i>
                  <div className="d-flex flex-column pl-2">
                    <h3 className="mb-0">{t("user-profile.fields.bonus")}</h3>

                    <h2 className="mb-0">
                      {" "}
                      {player.wallet?.bonus.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                      })}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-xl-6 order-xl-1 mb-2">
          <Deposit player={player} />
        </div>

        {/* withdrawal section */}
        <div className="order-xl-2 col-xl-6">
          <Withdrawal player={player} />
        </div>
      </div>

      <div className="row">
        {/* change password section */}
        <div className="col-xl-4 order-xl-2 mb-2">
          <div className="card bg-secondary shadow">
            <div className="card-header border-0 bg-simbalotto-green">
              <div className="row align-items-center">
                <div className="col-12">
                  <h3 className="mb-0 text-dark">
                    {t("user-profile.headings.change-password")}
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={passwordSubmit(handlePasswordChange)}>
                <h6 className="heading-small text-muted mb-4">
                  {t("user-profile.fields.password")}
                </h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-Password"
                        >
                          {t("user-profile.fields.password")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-Password"
                          placeholder={t("user-profile.fields.password")}
                          type="password"
                          name="password"
                          {...passwordRegister("password")}
                        />

                        {passwordErrors.password && (
                          <span className="text-danger">
                            {passwordErrors.password.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-PasswordConfirmation"
                        >
                          {t("user-profile.fields.confirm-password")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-PasswordConfirmation"
                          placeholder={t(
                            "user-profile.fields.confirm-password"
                          )}
                          type="password"
                          name="passwordConfirmation"
                          {...passwordRegister("confirm_password")}
                        />

                        {passwordErrors.confirm_password && (
                          <span className="text-danger">
                            {passwordErrors.confirm_password.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-lg btn-simbalotto-orange"
                  disabled={loading}
                >
                  <span className="btn-inner--icon text-dark">
                    {t("user-profile.buttons.change-password")}
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* profile section */}
        <div className="col-xl-8 order-xl-1">
          <div className="card bg-secondary">
            <div className="card-header border-0 bg-simbalotto-green">
              <div className="row align-items-center">
                <div className="col-6">
                  <h3 className="mb-0 text-dark">
                    {t("user-profile.headings.my-account")}
                    {player.kyc_status === "verified" && (
                      <i className="fas fa-check-circle text-info ml-2" />
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(handleUserProfileSubmit)}>
                <h6 className="heading-small text-muted mb-4">
                  {t("user-profile.headings.personal-info")}
                </h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-email"
                        >
                          {t("user-profile.fields.email")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-email"
                          placeholder="email@redfoxlotto.com"
                          type="email"
                          name="email"
                          disabled
                          {...register("email")}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-phone-number"
                        >
                          {t("user-profile.fields.phone-number")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-phone-number"
                          placeholder="7123456789"
                          type="text"
                          name="phone_number"
                          {...register("phone_number")}
                        />

                        {errors.phone_number && (
                          <span className="text-danger">
                            {errors.phone_number.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-first-name"
                        >
                          {t("user-profile.fields.first-name")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-first-name"
                          placeholder="First name"
                          type="text"
                          name="first_name"
                          {...register("first_name")}
                        />

                        {errors.first_name && (
                          <span className="text-danger">
                            {errors.first_name.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-last-name"
                        >
                          {t("user-profile.fields.last-name")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-last-name"
                          placeholder="Last name"
                          type="text"
                          name="last_name"
                          {...register("last_name")}
                        />

                        {errors.last_name && (
                          <span className="text-danger">
                            {errors.last_name.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-national_id"
                        >
                          {t("user-profile.fields.national-id")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-national_id"
                          placeholder="12345678"
                          type="text"
                          name="national_id"
                          {...register("national_id")}
                        />

                        {errors.national_id && (
                          <span className="text-danger">
                            {errors.national_id.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-birthday"
                        >
                          {t("user-profile.fields.birth-date")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-birthday"
                          placeholder="01/01/1990"
                          type="date"
                          name="birthday"
                          {...register("birth_date")}
                        />

                        {errors.birth_date && (
                          <span className="text-danger">
                            {errors.birth_date.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-timezone"
                        >
                          {t("user-profile.fields.timezone")}
                        </label>
                        <Controller
                          name="timezone"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              inputRef={ref}
                              value={timezones.filter((c) => c.value === value)}
                              onChange={(val) => onChange(val.value)}
                              options={timezones}
                              placeholder="Select Timezone"
                              className="form-control-alternative"
                            />
                          )}
                        />

                        {errors.timezone && (
                          <span className="text-danger">
                            {errors.timezone.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-gender"
                        >
                          {t("user-profile.fields.gender")}
                        </label>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              inputRef={ref}
                              value={genders.filter((c) => c.value === value)}
                              onChange={(val) => onChange(val.value)}
                              options={genders}
                              placeholder="Select Gender"
                              className="form-control-alternative"
                            />
                          )}
                        />

                        {errors.gender && (
                          <span className="text-danger">
                            {errors.gender.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />

                <h6 className="heading-small text-muted mb-4">
                  {t("user-profile.headings.contact-info")}
                </h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="country"
                        >
                          {t("user-profile.fields.country")}
                        </label>
                        <Controller
                          name="country"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              inputRef={ref}
                              value={countries.filter((c) => c.value === value)}
                              onChange={(val) => onChange(val.value)}
                              options={countries}
                              placeholder="Select Country"
                              className="form-control-alternative"
                            />
                          )}
                        />

                        {errors.country && (
                          <span className="text-danger">
                            {errors.country.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="region"
                        >
                          {t("user-profile.fields.region")}
                        </label>
                        <Controller
                          name="region"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              inputRef={ref}
                              value={countries
                                .filter((c) => c.value === watch("country"))
                                .map((c) => c.regions)
                                .flat()
                                .filter((r) => r.value === value)}
                              onChange={(val) => onChange(val.value)}
                              options={countries
                                .filter((c) => c.value === watch("country"))
                                .map((c) => c.regions)
                                .flat()}
                              placeholder="Select Region"
                              className="form-control-alternative"
                            />
                          )}
                        />

                        {errors.region && (
                          <span className="text-danger">
                            {errors.region.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="city"
                        >
                          {t("user-profile.fields.city")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-city"
                          placeholder="City"
                          type="text"
                          name="city"
                          {...register("city")}
                        />

                        {errors.city && (
                          <span className="text-danger">
                            {errors.city.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="input-address"
                        >
                          {t("user-profile.fields.address")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-address"
                          placeholder="address"
                          type="text"
                          name="address"
                          {...register("address")}
                        />

                        {errors.address && (
                          <span className="text-danger">
                            {errors.address.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label text-muted"
                          htmlFor="postal_code"
                        >
                          {t("user-profile.fields.postal-code")}
                        </label>
                        <input
                          className="form-control form-control-alternative"
                          id="input-postal_code"
                          placeholder={t("user-profile.fields.postal-code")}
                          type="text"
                          name="postal_code"
                          {...register("postal_code")}
                        />

                        {errors.postal_code && (
                          <span className="text-danger">
                            {errors.postal_code.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-lg btn-simbalotto-orange"
                  type="submit"
                  disabled={loading}
                >
                  <span className="btn-inner--icon text-dark">
                    {t("user-profile.buttons.update-profile")}
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-2">
        <div className="col-xl-8 order-xl-1">
          <div className="card bg-secondary shadow">
            <div className="card-header bg-danger border-0">
              <div className="row align-items-center">
                <div className="col-12">
                  <h3 className="mb-0 text-dark">
                    {t("user-profile.headings.danger-zone")}
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p className="mb-3 text-muted">
                {t("user-profile.texts.delete-account")}
              </p>

              <button
                type="button"
                className="btn btn-lg btn-danger"
                data-toggle="modal"
                data-target="#deleteModal"
              >
                {t("user-profile.buttons.delete-account")}
              </button>

              <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                aria-labelledby="deleteModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header bg-simbalotto-green">
                      <h4 className="text-white">
                        {t("user-profile.headings.delete-account-confirmation")}
                      </h4>
                    </div>
                    <div className="modal-body bg-secondary text-muted">
                      {t("user-profile.texts.delete-account-confirmation")}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        {t("user-profile.buttons.cancel")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleUserProfileDelete()}
                      >
                        {t("user-profile.buttons.delete")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
