import DatePickerCustom from "components/Table/datePicker";
import useDocumentTitle from "../../useDocumentTitle";
import TableComponent from "components/Table/index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React from "react";

const Tickets = () => {
  const history = useHistory();
  const { t } = useTranslation();
  useDocumentTitle(`${t("tickets.title")} | ${t("site-name")}`);

  const handleTicketCreate = () => {
    history.push(`/support-ticket/new`);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "subject",
        header: t("tickets.fields.subject"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
      },
      {
        accessorKey: "status",
        header: t("tickets.fields.status"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) => {
          return (
            <span
              className={`badge-dot mr-4 badge ${
                cell.row.original.status === "open"
                  ? "badge-success"
                  : cell.row.original.status === "closed"
                  ? "badge-danger"
                  : "badge-info"
              }`}
            >
              <i
                className={`${
                  cell.row.original.status === "open"
                    ? "bg-success"
                    : cell.row.original.status === "closed"
                    ? "bg-danger"
                    : "bg-info"
                }`}
              />
              {cell.row.original.status}
            </span>
          );
        },
      },
      {
        accessorFn: (row) => new Date(row.opened_at),
        id: "opened_at",
        header: t("tickets.fields.opened-at"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "actions",
        enableColumnFilter: false,
        enableGrouping: false,
        enableSorting: false,
        header: t("lotteries.fields.action"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) => (
          <button
            className="btn btn-simbalotto-orange text-uppercase"
            onClick={() => {
              history.push(`/support-ticket/view/${cell.row.original.id}`);
            }}
          >
            {t("lotteries.fields.view")}
          </button>
        ),
      },
    ],
    [history, t]
  );

  return (
    <div className="row">
      <div className="col-12 mb-3 text-right">
        <button
          className="btn btn-lg btn-simbalotto-orange"
          onClick={handleTicketCreate}
        >
          {t("tickets.new-ticket")}
        </button>
      </div>

      <div className="col-12" style={{ zIndex: 0 }}>
        <TableComponent
          title={t("tickets.title")}
          url="/api/v1/tickets?"
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Tickets;
