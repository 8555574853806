import React from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const tableRef = React.createRef(null);

function TableComponent(props) {
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [sorting, setSorting] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];

  const fetchData = React.useCallback(async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = props.url;
    url += `per_page=${pagination.pageSize}`;
    url += `&page=${pagination.pageIndex + 1}`;

    if (columnFilters.length) {
      const filter = columnFilters.map((filter) => {
        return `&${filter.id}=${filter.value}`;
      });
      url += filter.join("");
    }

    if (globalFilter) {
      url += `&q=${globalFilter ?? ""}`;
    }

    if (sorting.length) {
      url += `&sort=${sorting[0].id}&order=${sorting[0].desc ? "desc" : "asc"}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const text = await response.text();
        throw new Error(text.error);
      })
      .then((data) => {
        setData(data.data);
        setRowCount(data.pagination.totalCount);
      })
      .catch((error) => {
        setIsError(true);
      })
      .finally(() => {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
      });
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    props.url,
    token,
  ]);

  React.useEffect(() => {
    tableRef.current = fetchData;
    fetchData();
  }, [fetchData]);

  const table = useMaterialReactTable({
    columns: props.columns,
    data,
    rowCount,
    enableColumnPinning: true,
    initialState: {
      showColumnFilters: false,
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    headerTextTransform: "capitalize",
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: () => (
      <>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </>
    ),
  });

  return <MaterialReactTable table={table} />;
}

export { tableRef };
export default TableComponent;

TableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
};
